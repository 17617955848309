import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useIonRouter } from '@ionic/react';

import SearchCreateBox from '@/modules/searches/components/SearchCreateBox.tsx';

function HomeSectionSearch() {
  const { t } = useTranslation();
  const router = useIonRouter();

  return (
    <div className="flex max-w-full flex-row gap-4 overflow-hidden pb-8 lg:bg-gobazar-400 lg:px-10 lg:pb-0">
      <div className="flex max-w-full flex-1 flex-col gap-4 lg:min-w-[620px] lg:pb-20 xl:max-w-screen-md">
        <div className="relative flex min-h-54 flex-col gap-3 bg-gobazar p-6 py-8 md:min-h-64 lg:gap-6 lg:bg-gobazar-400">
          <div className="absolute -bottom-20 left-0 h-24 w-full rounded-b-2xl bg-gobazar lg:hidden"></div>
          <h1 className="xl:text-54 text-36 font-6 text-white lg:text-48 2xl:text-60">
            {t('home.economyTime')}
          </h1>
          <h2 className="!w-[calc(100%-8.5rem)] text-18 font-5 text-white lg:!w-auto lg:text-20 xl:text-24 2xl:text-28">
            {t('home.findProfitableOffers')}
          </h2>
        </div>

        <SearchCreateBox formClassName="p-6" isHome router={router} />
      </div>

      <div className="hidden flex-1 lg:flex">
        <img
          alt="hero-person"
          className="-mb-1 mt-auto aspect-square max-h-full min-h-[700px] w-full min-w-[700px] object-contain object-bottom lg:max-h-[800px]"
          loading="lazy"
          src="/images/home/home-person-lg.png"
        />
      </div>
    </div>
  );
}

export default memo(HomeSectionSearch);
